function ContactForm() {

  //<editor-fold desc="Event manager">
  var events = {};

  this.addEventListener = function (eventName, eventFunction) {
    events[eventName] = eventFunction;
  };
  //</editor-fold>

  var isPhone = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  var iHTML = "";
  var that = this;
  this.__sendButton = document.getElementById("sendform");

  var resetColors = function () {
    document.getElementById("inputname").style.border = "2px solid rgba(255, 255, 255, 0.5)";
    document.getElementById("inputemail").style.border = "2px solid rgba(255, 255, 255, 0.5)";
    document.getElementById("inputmessage").style.border = "2px solid rgba(255, 255, 255, 0.5)";
  };

  var postFormCompleted = function (response) {
    var json = JSON.parse(response);
    console.log(json);

    if (json.ERR == 1) {
      for (var i = 0; i < json.errors.length; i++) {
        var err = json.errors[i];
        var item = err.item;
        var message = err.message;
        createError(item, message);
      }

      that.__sendButton.innerHTML = iHTML;
      that.__sendButton.addEventListener("click", buttonClick);
    } else {
      // TODO: Success
      console.log("success");
      that.__sendButton.value = "GÖNDERİLDİ!";
    }
  };

  var postForm = function () {

    var post = {
      name: document.getElementById("inputname").value,
      email: document.getElementById("inputemail").value,
      message: document.getElementById("inputmessage").value
    };

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        postFormCompleted(xmlhttp.responseText);
      } else {
        if (!!xmlhttp.responseText) console.log(xmlhttp.responseText);
      }
    };
    xmlhttp.open("POST", "/tr/contactForm", true);
    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlhttp.send(JSON.stringify(post));
  };

  var destroyError = function () {

  };

  var createError = function (item, message) {

  };

  var buttonClick = function () {
    that.__sendButton.removeEventListener("click", buttonClick);

    iHTML = that.__sendButton.innerHTML;
    that.__sendButton.innerHTML = "Lütfen bekleyiniz....";

    var hasError = false;
    var errorMessage = "";

    destroyError();
    resetColors();

    setTimeout(function () {
      if (!document.getElementById("inputname").value.length) {
        hasError = true;
        document.getElementById("inputname").style.border = "2px solid red";
      }

      function isValidEmailAddress(emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
      }

      if (!isValidEmailAddress(document.getElementById("inputemail").value)) {
        hasError = true;
        document.getElementById("inputemail").style.border = "2px solid red";
      }

      if (!document.getElementById("inputmessage").value.length) {
        hasError = true;
        document.getElementById("inputmessage").style.border = "2px solid red";
      }

      if (hasError) {
        that.__sendButton.innerHTML = iHTML;
        that.__sendButton.addEventListener("click", buttonClick);
      } else {
        postForm();
      }
    }, 0);
  };

  this.init = function () {
    this.__sendButton.addEventListener("click", buttonClick);
  }
}

window.onload = function () {
  var contactForm = new ContactForm();
  contactForm.init();
};